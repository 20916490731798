import packageJson from '../../package.json';

export const environment = {
  production: false,
  applicationVersion: packageJson.version,
  donorPositionUrl: 'https://h8uhyenhsd.execute-api.us-east-1.amazonaws.com/dev/donor-position',
  connectConfigUrl:
    'https://h8uhyenhsd.execute-api.us-east-1.amazonaws.com/dev/chat',
  connectContactFlowId: '84c59e4e-114f-4096-89cf-7785270dbf17',
  connectInstanceId: '7603e38a-68b7-490b-a06d-ebee0506a0df',
  login_proxy_url:
    'https://h8uhyenhsd.execute-api.us-east-1.amazonaws.com/dev/login',
  x_api_key: 'HLFUUiz3YY8Mz2l8pS6rf510iNa6UPz046Vletcv', // mssapikey
  webex_token: {
    issuer:
      'Y2lzY29zcGFyazovL3VzL09SR0FOSVpBVElPTi8wNTdkMmE0OS1lYTYzLTRmNjYtYTQ2ZC04ZmNiZjY0YWUyZmU',
    secret: '/qHrZ3FUlFR26lr+bG8wdqZipYUTPmfJ46vOt/EoXRk=',
  },
  okta: {
    clientId: '0oa5dbmhju79WN0ST357',
    issuer: 'https://takeda.okta.com/oauth2/default',
    redirectUri: 'https://dev-mssr-donor.takeda.com/welcome',
    pkce: true,
  },
  location_list_url:
    'https://hq9tsv5kv5.execute-api.us-east-1.amazonaws.com/dev/centerslist',
  location_list_api_key: 'OhwnCrHF3pap9GTJxssAn8GoBUvEBPhR8NpTjMCc', // pdt-mss-center-list-api-key

  consent_error_msg_list_url:
    ' https://at5g0hg2u9.execute-api.us-east-1.amazonaws.com/dev/consent-errors',
  consent_error_msg_api_key: 'QKyigsbcRN8W1582uvb1s1bqxgMhBwys2xHPRsEF', // errormsgapikey
  fingerprint: {
    API_URL_CLOUDABIS: '',
    CenterNumber: '',
    CloudScannerAPI_BASEURL: 'https://dev-bioplugin-tec.onetakeda.com/BioPluginWebApi/',
    CloudScanner_DeviceName: 'Secugen',
    CloudScanner_TemplateFormat: 'M2ICS',
    CloudScanner_EngineName: 'FPRE02',
    CloudScanner_ClientAPIPath: 'http://localhost:15896/api/CloudScanr/FPCapture',
    CloudScanner_ClientInfo: 'http://localhost:15896/api/CloudScanr/ClientInfo',
    currentSite: {
      CenterNumber: '905'
    }
  },
  pdtDis: {
    donorDetails: 'https://ge7mw9o292.execute-api.us-east-1.amazonaws.com/dev',
    x_api_key: 'Ln48KKaAd15XFziKRHBf92hkvxWcUVZ68PvvCSXY', // donorkey
  },
  whitelisted_domains: '[' +
    '"https://h8uhyenhsd.execute-api.us-east-1.amazonaws.com",' +
    '"https://hq9tsv5kv5.execute-api.us-east-1.amazonaws.com",' +
    '"https://at5g0hg2u9.execute-api.us-east-1.amazonaws.com",' +
    '"https://ge7mw9o292.execute-api.us-east-1.amazonaws.com",' +
    '"https://dev-bioplugin-tec.onetakeda.com"' +
    ']'
};
