import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/login/components/login/login.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'welcome', component: OktaCallbackComponent },
  { path: 'donor-homepage', loadChildren: () => import('./modules/donor-homepage/donor-homepage.module')
    .then(m => m.DonorHomepageModule)
  },
  { path: 'donor-ringing', loadChildren: () => import('./modules/donor-ringing/donor-ringing.module')
    .then(m => m.DonorRingingModule)
  },      
  { path: '**', redirectTo: 'donor-homepage', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
