import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(private _oktaAuth: OktaAuthService, private _router: Router) {}

  login(originalUrl: any): void {
    // Save current URL before redirect
    sessionStorage.setItem('okta-app-url', originalUrl || this._router.url);

    // Launches the login redirect.
    this._oktaAuth.signInWithRedirect({
      scopes: ['openid', 'email', 'profile'],
    }).then(
      _ => this._router.navigate(['/donor-homepage'])
    );
  }
}