import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private tokenExtractor: HttpXsrfTokenExtractor
  ) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const allowedDomains = JSON.parse(environment.whitelisted_domains || '[""]');
    const extractedDomain = (req.url.trim().match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/) || [])[0];

    // Check if authorization header should be added to domain
    if (allowedDomains.includes(extractedDomain)) {
      let idToken: any;
      if (localStorage.getItem('okta-token-storage')) {
        idToken = JSON.parse(localStorage.getItem('okta-token-storage')!).idToken;
      }
      // else {
      //   idToken = {};
      //   idToken.value = 'eyJraWQiOiI3WWFacmpNcy03T1FYWXhOeHZ2eVZ5QWx6QWpqVDY2alNWeUZURGM2N25vIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVtZGg2cWJpQ3F2SFBsVTM1NyIsIm5hbWUiOiJNYW5zbyBHb256w6FsZXosIFBhYmxvIiwiZW1haWwiOiJwYWJsby5tYW5zby1nb256YWxlekB0YWtlZGEuY29tIiwidmVyIjoxLCJpc3MiOiJodHRwczovL3Rha2VkYS5va3RhLmNvbS9vYXV0aDIvZGVmYXVsdCIsImF1ZCI6IjBvYXBnNG9jbzZOQldhbGdBMzU3IiwiaWF0IjoxNjc2OTA3MTU5LCJleHAiOjE2NzY5MTA3NTksImp0aSI6IklELjFuR2pJQmwzRjc3dDFrSUtXMGNrZDFsZkNHRnk3NmZvRzVvOEFRSXNFTWsiLCJhbXIiOlsicHdkIl0sImlkcCI6IjAwb3UxbGhua0NXMThxRzFRMzU2Iiwibm9uY2UiOiJ1dTZHRGxSNHZ0NDhKUXJQMHlmSXh1cXRwVjVjdFVHNWFnQWZjYkpoazZBNVdmc1JlYXpQYlZHNlZVcURxRVVDIiwicHJlZmVycmVkX3VzZXJuYW1lIjoicGFibG8ubWFuc28tZ29uemFsZXpAdGFrZWRhLmNvbSIsImF1dGhfdGltZSI6MTY3NjkwNzE1NywiYXRfaGFzaCI6ImpySWozdVk5eVJCdnFVS1cwS3hUVGcifQ.m_iC4KFIUFZw1jwIKRg7MsfND_u1zVVratt9KOaDJtU12Nhf4duWVPXNJPCyXoQmTHDeNTlnxFlb5qnV_xczPBWILAdlMYkIiDNx1F_cQg_t-Zx30WOPZPKdLBWLtklI7DIqClYHxKUHy1odQ1m9NtsV0kQdi83FrOr-4zdW6bifu719Vn-w0cQ02CtEl_-otfL-aOVe9tumxURRXn9FO3UHj104nIA3k8jH0qL0TE5pSi-pNYIDftUpLVcHnd2noXgsx_yG8QejhUT6ZrduHMb8Ix1HLYM1vW7TAVJ4YNkML3xI5jC9yJMwH4LCCn32scDeYySRmoSX6EE9UFBFmQ'
      // }
      if (idToken) {
        let request = req.clone();
        let headers = req.headers;
        if (!headers.has('Authorization')) {
          headers = headers.set('Authorization', `Bearer ${idToken.idToken}`);
        }

        const xsrfToken = this.tokenExtractor.getToken();
        if (xsrfToken) {
          headers = headers.set('X-XSRF-TOKEN', xsrfToken);
        }
        request = req.clone({
          headers
        });

        return next.handle(request).pipe(catchError((err) => {
          if (err.status === 401 || err.status === 403) {
            this.router.navigateByUrl(`/error`)
              .catch(navErr => {
                console.error(`Unable to navigate to login page after ${err.status} error\n`, navErr);
              });
            return next.handle(req);
          }
          return throwError(err);
        }));
      }
    }
    return next.handle(req);

  }
}