<div class="donor-login-main">
    <img
      class="donor-login-biolife-logo"
      src="../assets/img/biolife_logo_350w.png"
      alt="image"
    />
    <div class="sign-in-container d-flex flex-column">
      <button class="sign-in-button" (click)="oktaAuth.login('/donor-homepage')">
        <img alt="okta-img" class="okta-img" src="../assets/img/okta_o.svg" />
        Sign In With Okta
      </button>
    </div>
    <div class="versioning">
      Version: {{ version }}
    </div>
</div>