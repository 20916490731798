import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  title = 'pdt-mss-donor-ui';
  version = environment.applicationVersion;

  constructor(public oktaAuth: AuthService) {}
}
